import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { EMPTY, Observable, Subject, startWith, takeUntil } from 'rxjs'
import { BaseCurrency } from '../../services/data/data.service'
import { LocationService, OpenStreeetMapNominatimGeocodingV1Response } from '../../services/location/location.service'
import { Upload } from '../../services/upload/upload.service'
import { Organization } from '../../models/organization.model'
import { FormErrorHandlerService } from '../../services/form-error-handler/form-error-handler.service'
import { random } from 'lodash'
import { OrganizationsService } from '../../services/organizations/organizations.service'

@Component({
  selector: 'shared-org-form-v2',
  templateUrl: './org-form-v2.component.html',
  styleUrls: ['./org-form-v2.component.scss']
})
export class OrgFormV2Component implements OnInit, OnDestroy, OnChanges {
  @Input() org: Organization
  @Input() validationErrors: ValidationErrors = null
  @Input() currencies: BaseCurrency[] = []
  @Input() countries: any[] = []
  @Output() formUpdate = new EventEmitter<FormGroup>()

  constructor(
    private locationService: LocationService,
    private formErrorHandlerService: FormErrorHandlerService,
    private orgService: OrganizationsService
  ) { }

  public avatarUpload$: Observable<Upload> = EMPTY
  private destroy$ = new Subject<void>()
  public genericError: string = null

  public orgFormDisabled = {
    'Business': false,
    'Private': false
  }

  public orgFormChangesLoading = false

  public form: FormGroup = new FormGroup({
    form: new FormControl('', Validators.required),
    currency: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    address1: new FormControl('', Validators.required),
    address2: new FormControl(''),
    city: new FormControl('', Validators.required),
    postcode: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    // vat_number: new FormControl(null),
    personal_number: new FormControl(null),
  })

  ngOnInit(): void {
    // Patch the form with input data
    if (this.org) {
      this.form.patchValue(this.org)
    }

    

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        startWith(this.form)
      )
      .subscribe(() => {
        this.orgFormDisabled.Private = this.isOrgFormDisabled(['SE'])
        this.formUpdate.emit(this.form)
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.org && changes.org.currentValue) {
      this.patchPristineFields(changes.org.currentValue)
    }

    if (changes.validationErrors && changes.validationErrors.currentValue) {
      console.info('this is the commming validationErrors', changes.validationErrors)
      if (changes.validationErrors.currentValue.message) {
        this.genericError = changes.validationErrors.currentValue.message
        return
      }
      if (changes.validationErrors.currentValue.err_type) {
        if (changes.validationErrors.currentValue.err_type === 'IncorrectOrgForm') {
          this.form.get('form').setErrors({ serverError: changes.validationErrors.currentValue.err_mess })
        }
        this.genericError = changes.validationErrors.currentValue.err_mess
        return
      }
      this.genericError = this.formErrorHandlerService.handleValidationErrors(this.form, changes.validationErrors.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public getGeoLocation(event) {
    this.locationService.getGeoLocation()
      .subscribe(
        (info: OpenStreeetMapNominatimGeocodingV1Response) => this.patchLocationInfo(info)
      )

    event.stopPropagation()
  }

  private patchLocationInfo(info: OpenStreeetMapNominatimGeocodingV1Response) {
    this.form.patchValue({
      // country: info?.address.country_code.toUpperCase(),
      city: info.address?.city ? info.address.city : info.address?.municipality,
      address1: info.address?.road,
      address2: info.address?.neighbourhood ? info.address?.neighbourhood + ', ' + info.address?.suburb : info.address?.suburb,
      postcode: info.address?.postcode
    })
  }

  private isOrgFormDisabled(onlyForCountries: string[]): boolean {
    const country: string = this.form.get('country').value

    let isDisabled = false

    if (onlyForCountries) {
      isDisabled = !onlyForCountries.includes(country)
    }
    return isDisabled
  }

  public onSubmitAvatar(file: File) {
    const org_id = this.org.id
    this.avatarUpload$ = this.orgService.uploadAndMapOrganizationAvatar(file, org_id)
    return this.avatarUpload$
  }

  private patchPristineFields(data: any): void {
    console.log('patching data: ', data)
    Object.keys(data).forEach(key => {
      const control = this.form.get(key)
      if (control && control.pristine) {
        control.patchValue(data[key])
      } else {
        console.log('not patching ', data[key])
      }
    })
  }

  public onOrgFormChange(value: string): void {
    this.orgFormChangesLoading = true
    const initialOrgFormDisabled = { ...this.orgFormDisabled }

    this.orgFormDisabled = {
      'Business': true,
      'Private': true
    }

    if (value) {
      this.form.get('form').patchValue(value)
    }

    setTimeout(() => {
      this.orgFormChangesLoading = false
      this.orgFormDisabled = initialOrgFormDisabled
    }, random(100, 1000))
  }

  public onPostcodeInput(event: any): void {
    let input = event.target.value.replace(/\D/g, '')  // Remove all non-digit characters
    if (input.length > 3) {
      input = input.substring(0, 3) + ' ' + input.substring(3, 5)  // Add a space after the 3rd digit
    }
    event.target.value = input
    this.form.controls['postcode'].setValue(input)  // Update form control value
  }
}
